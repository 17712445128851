<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row" v-bind:style= "[selectedLevel==='landelijk' ? 'custom-space-landelijk' : 'custom-space']">
      <div class="col-sm-12 down">

        <div v-if="selectedLevel==='landelijk'" class="d-flex align-items-center landelijk-warning" role="alert" aria-live="assertive" aria-atomic="true">
          <p class="landelijk-warning-message">Deze pagina is niet toegankelijk via het Landelijk niveau.</p>
        </div>

        <h1 class="intro-section-title">Leven met Dementie - Invulling van de dag</h1>
        <div class="row">
          <p class="intro-section-text">
            Onderstaand dashboard geeft informatie weer over de <b>Daginvulling</b> als onderdeel van de levenspadfase <b>Leven met Dementie</b>.
          </p>
          <!-- <p class="intro-section-text">
           Het doel van dit dashboard is om te laten zien waar mensen met dementie wonen en onder welke omstandigheden dit thuis kan zijn. Netwerken kunnen aan de hand van deze informatie hun beleid aanpassen om een goede mix van woonsituaties te creëren, passend bij de demografische gegevens van mensen met dementie binnen het netwerk. Er wordt een vergelijking gemaakt tussen de netwerken om te zien op welk gebied netwerken van elkaar kunnen leren. Daarnaast wordt er een prognose gedaan van het aantal verschillende woonplekken richting de toekomst zodat netwerken hierop kunnen anticiperen.
          </p> -->
        </div>
      </div>
    </div>

    <div class="row">

    <!-- <div class="col-sm-6">
      <card :hovertext="''">
        <template slot="card-header">CONCEPT Dagopvang mensen met dementie</template>
        <div slot="card-body">
          Gebruik dagopvang naar woonsituatie (percentage van het totaal aantal gebruikers Wmo-gefinancierde dagopvang).
            <pie-chart :x="[66, 34]" :y="['Samenwonend', 'Alleenstaand']"/>
        </div>
      </card>
    </div> -->

    <div class="col-sm-12" v-if="selectedLevel !=='landelijk'">
      <card :cardId="'Ontmoetingscentra en Odensehuizen'" :hovertext="'In deze grafiek worden ontmoetingscentra weergegeven. De kaart bestaat uit ontmoetingscentra van ontmoetingscentradementie.nl en de Odense huizen in van odensehuizen.nl.'">
        <template slot="card-header">Ontmoetingscentra en Odensehuizen</template>
          <div slot="card-body">
            <p class="graphtext">Hier volgt op korte termijn een overzicht van locaties voor zinvolle daginvulling, zoals Ontmoetingscentra, Odensehuizen en vergelijkbare locaties. Heeft u een overzicht van locaties in uw regio? Dan ontvangen wij deze graag op de hieronder vermelde contactgegevens.</p>
            <!-- <p class="graphtext">Op deze kaart van Nederland zie je de ontmoetingscentra. De groene punten zijn Odensehuizen en de blauwe punten zijn overige ontmoetingscentra.</p>
            <p>Let op: dit overzicht is nog niet compleet. Andere regionale of landelijke vormen van ontmoetings- en dagbestedingslocaties zijn niet weergegeven. Er wordt onderzocht of en hoe deze locaties kunnen worden toegevoegd aan dit overzicht.</p>
          <mapbox v-if="load.graphOne" :lat="lat" :lon="lon" :text="ontmoetingscentra"/> -->
        </div>
      </card>
    </div>

  </div>

  <div class="row" v-if="selectedLevel !=='landelijk'">
    <div class="col-sm-12 down">
      <card :cardId="'Aantal ontmoetingscentra en Odensehuizen per regio'" :hovertext="'In deze grafiek wordt het aantal ontmoetingscentra en Odensehuizen per regio weergegeven. Het aantal locaties is gebaseerd op het laatste jaar (2022). Regio’s zonder ontmoetingscentrum of Odensehuis zijn weggelaten.'">
        <template slot="card-header">Aantal ontmoetingscentra en Odensehuizen per regio</template>
        <div slot="card-body">
            <p class="graphtext">Hier volgt op korte termijn een overzicht van locaties voor zinvolle daginvulling, zoals Ontmoetingscentra, Odensehuizen en vergelijkbare locaties. Heeft u een overzicht van locaties in uw regio? Dan ontvangen wij deze graag op de hieronder vermelde contactgegevens.</p>
            <!-- <p class="graphtext"> Onderstaande grafiek geeft inzicht in het aantal ontmoetingscentra en Odensehuizen per regio.</p>
            <p>Let op: dit overzicht is nog niet compleet. Andere regionale of landelijke vormen van ontmoetings- en dagbestedingslocaties zijn niet weergegeven. Er wordt onderzocht of en hoe deze locaties kunnen worden toegevoegd aan dit overzicht.</p>
            <stacked-bar-chart-colors-numbers v-if="load.graphTwo" :tickangle="45" :ytitle="'aantal mensen met dementie'" :name1="'mensen per ontmoetingscentra'" :x1="regio" :y1="centra" :showlegend="false"/> -->
        </div>
      </card>
    </div>
  </div>
  <div class="row" v-if="selectedLevel !=='landelijk'">
    <div class="col-sm-12 down">
      <card :cardId="'Aantal mensen met dementie per ontmoetingscentra'" :hovertext="'In deze grafiek wordt het aantal mensen met dementie ten opzichte van het aantal ontmoetingscentra en Odensehuizen per regio weergegeven. De aantallen mensen met dementie zijn verschillend per jaar, de opvang locaties zijn gebaseerd op het laatste jaar (2022). Regio’s zonder ontmoetingscentrum of Odensehuis zijn weggelaten.'">
        <template slot="card-header">Aantal mensen met dementie per ontmoetingscentra</template>
        <div slot="card-body">
          <p class="graphtext">Hier volgt op korte termijn een overzicht van locaties voor zinvolle daginvulling, zoals Ontmoetingscentra, Odensehuizen en vergelijkbare locaties. Heeft u een overzicht van locaties in uw regio? Dan ontvangen wij deze graag op de hieronder vermelde contactgegevens.</p>
            <!--<p class="graphtext"> Onderstaande grafiek geeft inzicht in het aantal mensen met dementie per ontmoetingscentra per regio.</p>
            <p>Let op: dit overzicht is nog niet compleet. Andere regionale of landelijke vormen van ontmoetings- en dagbestedingslocaties zijn niet weergegeven. Er wordt onderzocht of en hoe deze locaties kunnen worden toegevoegd aan dit overzicht. </p>
            <stacked-bar-chart-colors-numbers v-if="load.graphTwo" :tickangle="45" :ytitle="'aantal mensen met dementie'" :name1="'mensen per ontmoetingscentra'" :x1="regio" :y1="aantal" :showlegend="false"/>-->
        </div>
      </card>
    </div>
  </div>

  <div class="row" v-if="selectedLevel !=='landelijk'">
    <div class="col-sm-12">
      <card-large :cardId="'Aantal thuiswonende mensen met dementie per FTE casemanager'" v-if="selectedLevel==='dementienetwerk'" :hovertext="'De gegevens in deze grafiek worden berekend door het aantal geïdentificeerde thuiswonende mensen met dementie te delen door het aantal casemanagers binnen het netwerk. Het aantal casemanagers wordt door dementienetwerken aangeleverd aan de NZa en kan worden bezocht op: https://puc.overheid.nl/nza/doc/PUC_721599_22/'" 
        :x="netwerknaam" :y="thuiswonend_per_fte.map(value => `${value}`)" 
        :xtitle="'Netwerk naam'" :ytitle="'Thuiswonend per fte'"  :tableHeight='1200'>
        <template slot="card-header">Aantal thuiswonende mensen met dementie per FTE casemanager</template>
        <div slot="card-body">
          <p class="graphtext"> In dit overzicht zie je het gemiddeld aantal mensen met dementie per FTE casemanager voor verschillende regios. Dit diagram kan inzicht geven in de relatieve werkdruk van casemanagers.</p>
          <p>Let op: Dit is gebaseerd op het aantal thuiswonende mensen met dementie. In werkelijkheid krijgt of wil niet iedereen casemanagement, waardoor deze analyse slechts een bovengrens van de caseload van de casemanagers is. Daarnaast kan in sommige regio's andere invulling worden gegeven aan casemanagement. Daardoor kan deze grafiek een incompleet beeld geven van de relatieve werkdruk van casemanagers. </p>
          <p>Tip: als u over de grafiek beweegt met uw muis kunt u de informatie beter lezen.</p>
          <horizontal-bar-chart-numbers
          :x="thuiswonend_per_fte"
          :y="netwerknaam"
          :marginleft="200"
          :height="1200"
          />
        </div>
      </card-large>
      <card-small :cardId="'Mensen met dementie per FTE casemanager'" v-if="selectedLevel==='zorgkantoor'" :hovertext="''">
        <template slot="card-header">Mensen met dementie per FTE casemanager - uitsluitend op dementienetwerk-niveau</template>
      </card-small>
    </div>

    <!-- <div class="col-sm-6">
      <card :hovertext="'Het verschil in kosten per soort wordt berekend door voor elk van de kostensoorten het gemiddelde uit te rekenen van mensen met een casemanager en mensen zonder een casemanager.'">
        <template slot="card-header">CONCEPT Verschil in kosten</template>
        <div slot="card-body">
          <p class="graphtext"> In onderstaand diagram zie je het verschil in gemiddelde kosten tussen een persoon met dementie met casemanager en zonder casemanager. De kosten worden uitgesplitst in verschillende soorten kosten. </p>
          <waterfall
          :y="[20000, -1000, 1500, 700, -300, 0, 0, 20900]"
          :x="['Met Casemanager', 'Wlz', 'Ziekenhuis', 'Psychologisch', 'Huisarts', 'Farmaceutisch', 'Overig', 'Zonder Casemanager']"
          :measure="['absolute', 'relative', 'relative', 'relative', 'relative', 'relative', 'relative', 'total']"
          />
        </div>
      </card>
    </div> -->
  </div>

  <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>

import Card from '../components/Card'
// import PieChart from '../components/PieChart'
import HorizontalBarChartNumbers from '../components/HorizontalBarChartNumbers'
import CardSmall from '../components/CardSmall'
import CardLarge from '../components/CardLarge'
// import Mapbox from '@/components/Mapbox'
//import StackedBarChartColorsNumbers from '../components/StackedBarChartColorsNumbers'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Leven met Dementie - Invulling van de dag',
  },
  components: { Card, CardLarge, CardSmall, HorizontalBarChartNumbers, Footer },
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      locations: [],
      text: [],
      z: [],
      ontmoetingscentra: [],
      lat: [],
      lon: [],
      aantal: [],
      regio: [],
      centra: [],
      thuiswonend_per_fte: [],
      netwerknaam: [],
      load: {
        graphOne: false,
        graphTwo: false,
        graphEight: false,
      }
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  methods: {
    loadAllData () {
      // if (level === 'zorgkantoor') {
      //   this.$http.get(`/api/ontmoetingscentra_aantal/get_data_zorgkantoor/?jaar=${this.$store.state.selectedYear}`).then(
      //     (response) => {
      //       response.data.sort((a,b) => b.mensen_per_ontmoetingscentra-a.mensen_per_ontmoetingscentra)
      //       response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
      //       this.aantal = response.data.map(d => (d.mensen_per_ontmoetingscentra.toFixed(0)))
      //       this.centra = response.data.map(d => (d.aantal_ontmoetingscentra))
      //       this.regio = response.data.map(d => d.zorgkantoorregionaam)
      //       this.load.graphTwo=true
      //     }
      //   )
      // }
      // else {
      //   this.$http.get(`/api/ontmoetingscentra_aantal/get_data_dementienetwerk/?jaar=${this.$store.state.selectedYear}`).then(
      //     (response) => {
      //       response.data.sort((a,b) => b.mensen_per_ontmoetingscentra-a.mensen_per_ontmoetingscentra)
      //       response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
      //       this.aantal = response.data.map(d => (d.mensen_per_ontmoetingscentra.toFixed(0)))
      //       this.centra = response.data.map(d => (d.aantal_ontmoetingscentra))
      //       this.regio = response.data.map(d => d.dementienetwerknaam)
      //       this.load.graphTwo=true
      //     }
      //   )
      // }
      this.$http.get(`/api/dementienetwerk_casemanagers/?peildatum=2022-01-01`).then(
        (response) => {
          response.data.sort((b,a) => b.aantal_thuiswonend_per_FTE-a.aantal_thuiswonend_per_FTE)
          this.netwerknaam = response.data.map(d => d.dementienetwerknaam)
          this.thuiswonend_per_fte = response.data.map(d => d.aantal_thuiswonend_per_FTE)
          this.load.graphEight=true
        }
      )
    },
    // loadOntmoetingscentra () {
    //   this.$http.get('/api/ontmoetingscentra').then(
    //     (response) => {
    //       this.ontmoetingscentra = response.data.map(d => d.ontmoetingscentrumnaam)
    //       this.lat = response.data.map(d => d.latitude)
    //       this.lon = response.data.map(d => d.longitude)
    //       this.load.graphOne=true
    //     }
    //   )
    // }
  },
  mounted () {
    this.loadAllData(this.selectedLevel)//,
    // this.loadOntmoetingscentra()
  }
}
</script>

<style scoped>

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

.landelijk-warning{
  background-color: #f77b72;
  padding: 3rem;
  border-radius: 15px;
  height: 50px;
  width: 100%;
  border: 5px solid #f6685e;
  margin-bottom: 5vh;
}

.landelijk-warning-message{
  font-weight: bold;
  color: var(--color-2);
}

.custom-space{
  margin-bottom: -1.5rem;
}

.custom-space-landelijk{
  margin-bottom: 2rem;
}
</style>